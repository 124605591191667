import { cls, whisper } from '@/utils'
import { useMemo, Dispatch, SetStateAction } from 'react'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
import TemplateWidget from './widget'
import Loading from '@/components/loading'
import { TemplateInputWidget } from '@/types'

export interface TemplateWidgetsProps {
  className?: string
  inputs: TemplateInputWidget[]
  templateInputs?: Record<string, any>
  setTemplateInputs?: Dispatch<SetStateAction<Record<string, any>>>
  onReadyStateChange?: (key: string, ready: boolean) => void
}

export default function TemplateWidgets({
  className,
  inputs,
  templateInputs,
  setTemplateInputs,
  onReadyStateChange,
}: TemplateWidgetsProps) {
  const seperator = useMemo(
    () => (
      <div className='flex items-center justify-center p-4 shrink-0 last-of-type:hidden' aria-label='seperator'>
        <IconPlus className='size-5 text-icon-subdued shrink-0' />
      </div>
    ),
    [],
  )

  const readyStateUpdaters = useMemo(() => {
    return inputs?.reduce(
      (acc, widget) => {
        acc[widget.key] = (ready: boolean) => onReadyStateChange?.(widget.key, ready)
        return acc
      },
      {} as Record<string, (ready: boolean) => void>,
    )
  }, [inputs, onReadyStateChange])

  return (
    <fieldset
      className={cls(
        'self-start flex flex-col gap-1 p-3 pt-0 bg-surface w-full shrink-0 justify-center items-center',
        className,
      )}
      aria-label='creation-input-fieldset'
    >
      {inputs?.length ? (
        <div className='w-full h-full flex flex-col md:flex-row items-center justify-start md:justify-center overflow-y-auto'>
          {inputs.map((widget) => {
            return (
              <>
                <TemplateWidget
                  inputWidget={widget}
                  templateInputs={templateInputs!}
                  setTemplateInputs={setTemplateInputs!}
                  onReadyStateChange={readyStateUpdaters[widget.key]}
                />
                {seperator}
              </>
            )
          })}
        </div>
      ) : (
        <div className='h-[166px] w-full flex items-center justify-center'>
          <Loading className='h-6' />
        </div>
      )}
      {/* {showTextarea && <div className='flex w-full flex-col'>{textarea}</div>} */}
    </fieldset>
  )
}

'use client'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import Upload from '@/components/upload'
import { CLOTHING_TYPES, IMAGE_ACCEPT } from '@/constants'
import { buildFileFromSample, TemplateWidgetProps } from './common'
import { cls, whisper } from '@/utils'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import TemplateSample from './sample'
import Button from '@/components/button'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import { UploadSample } from '@/components/gs-upload/sample-dialog'
import Guidance from '@/components/guidance'
import Dialog from '@/components/dialog'
import Image from '@/components/image'
import { AnyObject, InnerUploadFile } from '@/types'

export default function Garment({
  inputWidget,
  templateInputs,
  setTemplateInputs,
  onReadyStateChange,
}: TemplateWidgetProps) {
  const { key, name, samples, privateProps } = inputWidget ?? {}
  const showMultipleGarments = templateInputs?.input_clothing_type === CLOTHING_TYPES.MULTIPLE_GARMENTS
  const topSamples = useMemo(() => samples?.filter((sample) => sample?.type === 'top') ?? [], [samples])
  const bottomSamples = useMemo(() => samples?.filter((sample) => sample?.type === 'bottom') ?? [], [samples])
  const setSamples = useMemo(() => samples?.filter((sample) => sample?.type === 'set_or_dress') ?? [], [samples])

  const topGarmentFile = templateInputs.input_clothing_top_image_url
  const bottomGarmentFile = templateInputs.input_clothing_bottom_image_url

  const setTopGarment = useCallback(
    (file: InnerUploadFile | null) => {
      // whisper('setTopGarment file is: ', file)
      setTemplateInputs?.((prev) => ({ ...prev, input_clothing_top_image_url: file }))
    },
    [setTemplateInputs],
  )

  const setBottomGarment = useCallback(
    (file: InnerUploadFile | null) => {
      // whisper('setBottomGarment file is: ', file)
      setTemplateInputs?.((prev) => ({ ...prev, input_clothing_bottom_image_url: file }))
    },
    [setTemplateInputs],
  )

  const ready = useMemo(() => {
    if (!templateInputs.input_clothing_type) return false
    if (showMultipleGarments) {
      return !!topGarmentFile && !!bottomGarmentFile
    } else {
      return !!templateInputs?.[key]
    }
  }, [templateInputs, showMultipleGarments, topGarmentFile, bottomGarmentFile, key])

  whisper('templateInputs is: ', templateInputs)
  whisper('Garment ready: ', ready)

  useEffect(() => {
    onReadyStateChange?.(ready)
  }, [ready, onReadyStateChange])

  // templateInputs?.[key]?.url
  const pickedUrls = useMemo(() => {
    if (showMultipleGarments) {
      return [topGarmentFile?.url ?? '', bottomGarmentFile?.url ?? ''].filter(Boolean)
    } else {
      return [templateInputs?.[key]?.url ?? '']
    }
  }, [showMultipleGarments, bottomGarmentFile, topGarmentFile, key, templateInputs])

  const handlePickSample = useCallback(
    async (sample: UploadSample) => {
      const file = await buildFileFromSample(sample, 'image')
      if (showMultipleGarments) {
        if (sample?.type === 'top') {
          setTopGarment(file)
        } else if (sample?.type === 'bottom') {
          setBottomGarment(file)
        } else {
          // fallback to top
          setTopGarment(file)
        }
      } else {
        const newClothingType =
          sample?.type === 'top'
            ? CLOTHING_TYPES.TOP
            : sample?.type === 'bottom'
              ? CLOTHING_TYPES.BOTTOM
              : sample?.type === 'set_or_dress'
                ? CLOTHING_TYPES.SET_OR_DRESS
                : null
        setTemplateInputs?.((prev) => ({
          ...prev,
          ...sample?.extra,
          input_clothing_type: newClothingType ?? prev.input_clothing_type,
          [key]: file,
        }))
      }
    },
    [setTemplateInputs, showMultipleGarments, setTopGarment, setBottomGarment, key],
  )

  const handleValueChange = useCallback(
    (value: any) => {
      setTemplateInputs?.((prev) => ({ ...prev, [key]: value }))
    },
    [key, setTemplateInputs],
  )

  const lastGarmentTypeRef = useRef<number | null>(templateInputs?.input_clothing_type ?? null)

  const handleGarmentTypeChange = useCallback(
    (value: any) => {
      value = value ? Number(value) : value
      if (lastGarmentTypeRef.current === value || !value) return

      setTemplateInputs?.((prev) => {
        const result: AnyObject = {
          ...prev,
          input_clothing_type: value,
        }

        if (lastGarmentTypeRef.current === CLOTHING_TYPES.MULTIPLE_GARMENTS) {
          if (value === CLOTHING_TYPES.TOP) {
            result[key] = prev.input_clothing_top_image_url
          } else if (value === CLOTHING_TYPES.BOTTOM) {
            result[key] = prev.input_clothing_bottom_image_url
          }
          result.input_clothing_top_image_url = null
          result.input_clothing_bottom_image_url = null
        } else if (value === CLOTHING_TYPES.MULTIPLE_GARMENTS) {
          if (lastGarmentTypeRef.current === CLOTHING_TYPES.TOP) {
            result.input_clothing_top_image_url = prev[key]
          } else if (lastGarmentTypeRef.current === CLOTHING_TYPES.BOTTOM) {
            result.input_clothing_bottom_image_url = prev[key]
          }

          result[key] = null
        }

        return result
      })

      lastGarmentTypeRef.current = value
    },
    [setTemplateInputs, key],
  )

  const quickSampleCount = 4
  const quickSamples = useMemo(() => {
    if (templateInputs?.input_clothing_type === CLOTHING_TYPES.TOP) {
      return topSamples.slice(0, quickSampleCount)
    } else if (templateInputs?.input_clothing_type === CLOTHING_TYPES.BOTTOM) {
      return bottomSamples.slice(0, quickSampleCount)
    } else if (templateInputs?.input_clothing_type === CLOTHING_TYPES.SET_OR_DRESS) {
      return setSamples.slice(0, quickSampleCount)
    } else {
      const topPart = topSamples?.slice(0, quickSampleCount / 2)
      const bottomPart = bottomSamples?.slice(0, quickSampleCount / 2)
      return [...topPart, ...bottomPart]
    }
  }, [topSamples, bottomSamples, templateInputs?.input_clothing_type, setSamples])

  const availableSamples = useMemo(() => {
    if (templateInputs?.input_clothing_type === CLOTHING_TYPES.TOP) {
      return topSamples
    } else if (templateInputs?.input_clothing_type === CLOTHING_TYPES.BOTTOM) {
      return bottomSamples
    } else if (templateInputs?.input_clothing_type === CLOTHING_TYPES.SET_OR_DRESS) {
      return setSamples
    } else if (templateInputs?.input_clothing_type === CLOTHING_TYPES.MULTIPLE_GARMENTS) {
      return [...topSamples, ...bottomSamples]
    } else {
      return samples
    }
  }, [topSamples, bottomSamples, templateInputs?.input_clothing_type, setSamples, samples])

  return (
    <div className='flex flex-col gap-2 items-center w-70'>
      <div className='relative w-full h-5 flex justify-center items-center gap-1'>
        <span className='text-body-md font-medium tracking-15'>{name}</span>
        <Dialog
          asChild
          className='w-full md:w-[544px]'
          title='Guideline for Garment Images'
          footer={null}
          trigger={
            <Button
              variant='transparent'
              className='grow-0 shrink-0 p-0 h-5 max-w-5 hover:bg-transparent active:bg-transparent'
            >
              <IconInfo className='size-5 text-icon-subdued hover:opacity-80' />
            </Button>
          }
        >
          <div className=''>
            <Guidance
              className=''
              rules={[
                'Single garment, flat lay, unfolded, clear details, unobstructed.',
                'Dresses and sets can include multiple items.',
                'White or light plain background, item centred in the image.',
              ]}
              goodExamples={[
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-41-44-2bebe265.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-07-aaefc3fe.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-13-3c9a0b2c.webp',
                'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-42-16-edd5c68e.webp',
              ]}
              badExamples={[
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-39-30-26c96098.webp',
                  description: 'Overlay text',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-39-43-df1f4249.webp',
                  description: 'Unclean BG',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-40-34-ce249318.webp',
                  description: 'Folded',
                },
                {
                  url: 'https://cdnvb5.haiper.ai/u/AF2qq2KJJXWoYcZUY3c651ARpK73/2024-12-10T13-41-39-63a08dba.webp',
                  description: 'Multi-garment',
                },
              ]}
              content='No strict ratio required, but 1:1 or 3:4 is recommended (e.g., 1024 × 124 or 768 × 1024). Short side ≥512px, long side ≤4096px. ≤20MB in JPG, PNG or WebP.'
            />
          </div>
        </Dialog>
      </div>
      <Select value={templateInputs?.input_clothing_type} onValueChange={handleGarmentTypeChange}>
        <SelectTrigger className='w-full rounded-md border-border border-b-2 hover:bg-surface-hover hover:border-border-hover h-8 flex items-center px-2 py-1'>
          <div className='flex items-center flex-1 overflow-hidden'>
            <div className='text-left pl-2 flex-1 overflow-hidden text-ellipsis'>
              <SelectValue placeholder='Garment type' />
            </div>
          </div>
        </SelectTrigger>
        <SelectContent className='border px-0 w-full'>
          <SelectItem
            className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text overflow-hidden text-ellipsis'
            value={CLOTHING_TYPES.TOP as any}
          >
            <div className={cls('w-full overflow-hidden text-ellipsis max-w-calc-100vw-minus-134px pr-8')}>Top</div>
          </SelectItem>
          <SelectItem
            className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text overflow-hidden text-ellipsis'
            value={CLOTHING_TYPES.BOTTOM as any}
          >
            <div className={cls('w-full overflow-hidden text-ellipsis max-w-calc-100vw-minus-134px pr-8')}>Bottom</div>
          </SelectItem>
          <SelectItem
            className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text overflow-hidden text-ellipsis'
            value={CLOTHING_TYPES.SET_OR_DRESS as any}
          >
            <div className={cls('w-full overflow-hidden text-ellipsis max-w-calc-100vw-minus-134px pr-8')}>
              Set or dress
            </div>
          </SelectItem>
          <SelectItem
            className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text overflow-hidden text-ellipsis'
            value={CLOTHING_TYPES.MULTIPLE_GARMENTS as any}
          >
            <div className={cls('w-full overflow-hidden text-ellipsis max-w-calc-100vw-minus-134px pr-8')}>
              Multiple Garments
            </div>
          </SelectItem>
        </SelectContent>
      </Select>
      {showMultipleGarments ? (
        <div className='w-full flex justify-between items-start gap-2.5'>
          <Upload
            key='input_clothing_top_image_url'
            accept={IMAGE_ACCEPT}
            maxFileSize={20 * 1024 * 1024}
            file={topGarmentFile ?? null}
            fileType='image'
            className={cls('border flex-1 h-32')}
            emptyText={
              <div className='flex flex-col items-center text-center'>
                <span className='text-body-md tracking-15'>Upload Top</span>
                {/* <span className='text-body-sm text-text-subdued'>JPG/PNG/WebP, 512px ≤ side ≤ 4096px</span> */}
              </div>
            }
            onChange={setTopGarment}
            {...privateProps}
          />
          <Upload
            key='input_clothing_bottom_image_url'
            accept={IMAGE_ACCEPT}
            maxFileSize={20 * 1024 * 1024}
            file={bottomGarmentFile ?? null}
            fileType='image'
            className={cls('border flex-1 h-32')}
            emptyText={
              <div className='flex flex-col items-center text-center'>
                <span className='text-body-md tracking-15'>Upload Bottom</span>
                {/* <span className='text-body-sm text-text-subdued'>JPG/PNG/WebP, 512px ≤ side ≤ 4096px</span> */}
              </div>
            }
            onChange={setBottomGarment}
            {...privateProps}
          />
        </div>
      ) : (
        <Upload
          key={key}
          accept={IMAGE_ACCEPT}
          maxFileSize={20 * 1024 * 1024}
          file={templateInputs?.[key] ?? null}
          fileType='image'
          className={cls('border w-full h-32')}
          emptyText={
            <div className='flex flex-col items-center text-center'>
              <span className='text-body-md tracking-15'>Upload image</span>
              <span className='text-body-sm text-text-subdued'>JPG/PNG/WebP, 512px ≤ side ≤ 4096px</span>
            </div>
          }
          onChange={handleValueChange}
          {...privateProps}
        />
      )}
      <div className='w-full flex items-center gap-2' aria-label='quick samples'>
        {quickSamples?.map((sample) => {
          // const selected = templateInputs?.[key]?.url === sample.url
          const selected = pickedUrls.includes(sample.url)
          return (
            <div
              key={sample.url}
              className={cls(
                'size-16 aspect-square overflow-hidden rounded-md border p-0.5 cursor-pointer',
                selected && 'border-border-active border-2 p-px',
              )}
              aria-label='quick sample'
              onClick={() => handlePickSample(sample)}
            >
              <Image src={sample.url} alt={sample.name} className='size-full rounded-sm object-cover' />
            </div>
          )
        })}
      </div>
      <TemplateSample
        name='More Garments'
        className='w-full'
        samples={availableSamples}
        inputWidget={inputWidget}
        setTemplateInputs={setTemplateInputs}
        onPickSample={handlePickSample}
      />
    </div>
  )
}

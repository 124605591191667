'use client'

import Button from '@/components/button'
import { useCallback, useState } from 'react'
import { cls } from '@/utils'
import IconAIStar from '@haiper/icons-svg/icons/solid/ai-star.svg'
import UploadSampleDialog, { UploadSample } from '@/components/gs-upload/sample-dialog'
import { buildFileFromSample, TemplateWidgetProps } from './common'
import { PoNVoid } from '@/types'

export interface TemplateSampleProps extends Pick<TemplateWidgetProps, 'setTemplateInputs' | 'inputWidget'> {
  name?: string
  className?: string
  samples?: UploadSample[]
  onPickSample?: (sample: UploadSample) => PoNVoid
}

export default function TemplateSample({
  className,
  samples,
  setTemplateInputs,
  inputWidget,
  name = 'Ideas',
  onPickSample,
}: TemplateSampleProps) {
  const [showSampleDialog, setShowSampleDialog] = useState(false)

  const handlePickSample = useCallback(
    async (sample: UploadSample) => {
      setShowSampleDialog(false)
      if (onPickSample) {
        await onPickSample(sample)
        return
      }
      const file = await buildFileFromSample(sample, 'image')
      setTemplateInputs?.((prev) => ({
        ...prev,
        ...sample?.extra,
        [inputWidget.key]: file,
      }))
    },
    [onPickSample, inputWidget, setTemplateInputs],
  )

  return (
    <div className={cls('flex flex-col', className)}>
      <Button
        variant='outline'
        className='w-full h-8 shrink-0 rounded-md'
        disabled={!samples?.length}
        onClick={() => setShowSampleDialog(true)}
      >
        <div className='flex items-center gap-1'>
          <IconAIStar className='size-4 text-icon-interactive' />
          <span>{name}</span>
        </div>
      </Button>
      <UploadSampleDialog
        showSearch
        fileType='image'
        open={showSampleDialog}
        title={`Ideas - ${name}`}
        samples={samples ?? []}
        onOpenChange={() => setShowSampleDialog(false)}
        onCancel={() => setShowSampleDialog(false)}
        onPickSample={handlePickSample}
      />
    </div>
  )
}
